<template>
  <div>
    <!-- 空白跳转页 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      PrintNum: ''
    }   
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.PrintNum = this.getQueryVariable('PrintNum')
      this.$router.push({path: '/index',query: {PrintNum: this.PrintNum}})
    },
    //获取URL地址的参数
    getQueryVariable(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split("&")
      for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=")
        if(pair[0] == variable) {return pair[1]}
      }
      return(false)
    }    
  }
}
</script>
<style lang="scss" scoped>
 
</style>